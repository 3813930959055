import { GeolocationService } from './../../services/geolocation/geolocation.service';
import { ApiService } from './../../services/api/api.service';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { SettingsService } from 'src/app/services/settings/settings.service';
import { EnvironmentService } from 'src/app/services/environment/environment.service';
import { CategoriesService } from 'src/app/services/categories/categories.service';
import { Category } from 'src/app/interfaces/category';
import { Component, Input, OnInit, OnChanges, OnDestroy, ViewChild } from '@angular/core';


@Component({
  selector: 'app-page-header',
  templateUrl: './page-header.component.html',
  styleUrls: ['./page-header.component.scss'],
})
export class PageHeaderComponent implements OnInit, OnChanges, OnDestroy {

  @Input() active_category: Category = null;
  @Input() title: string = null;
  @Input() search_query: string = '';
  @Input() nav_show_active: boolean = true;
  @Input() search_location: string = '';

  @ViewChild('submitBtn') submitBtn;

  home_image_url: string;
  home_title: string;

  title_is_home_title = false;

  private settings_sub: Subscription = null;
  private active_category_sub: Subscription = null;


  date_input: string = '';
  time_input: string = '';

  on_bonnie: boolean;

  get date_url() {
    return encodeURIComponent(this.date_input);
  }

  get time_url() {
    return encodeURIComponent(this.time_input);
  }

  get submit_link() {
    if (this.search_query === '' && this.search_location !== '') {
      return '/search/0/' + this.search_location;
    }

    if (this.search_query !== '' && this.search_location === '') {
      return '/search/' + this.search_query;
    }

    return '/search/' + this.search_query + '/' + this.search_location;
  }

  get image_link() {
    return this.active_category?.image_url || this.environment.settings.salon.salon_banner;
  }

  latitude: number;
  longitude: number;

  postcodes: string[] = [];
  postcodes_total = 0;

  dropdownShow = false;

  constructor(
    private categoriesService: CategoriesService,
    private environment: EnvironmentService,
    private settingsService: SettingsService,
    private router: Router,
    private api: ApiService,
    private geolocation: GeolocationService,
  ) { }

  ngOnInit() {
    this.setup();
    this.settings_sub = this.settingsService.settings$.subscribe(response => {
      this.home_title = response.app?.home_page_title;
      this.home_image_url = response.salon.salon_banner;

      if (this.title_is_home_title === true) {
        this.title = this.home_title;
      }
    });
    if (this.search_query === '0') {
      this.search_query = '';
    }
    this.on_bonnie = this.environment.api.salon_id === null;
  }

  submitForm() {
    this.submitBtn.nativeElement.click();
  }

  ngOnChanges() {
    this.setup();
  }

  ngOnDestroy() {
    if (this.settings_sub !== null) {
      this.settings_sub.unsubscribe();
      this.settings_sub = null;
    }
  }

  private setup() {
    if (this.active_category_sub === null) {
      this.active_category = this.categoriesService.getActiveCategory();
      this.active_category_sub = this.categoriesService.active_category$.subscribe(active_category => {
        this.active_category = active_category;
    
        if (this.active_category !== null && this.active_category !== undefined) {
          if (! this.checkOnHomePage()) {
            this.title = 'Book ' + this.active_category?.name;
          }
        }
    
        this.home_image_url = this.environment.settings.salon.salon_banner;
        this.home_title = this.environment.settings.app.home_page_title;
    
        if (this.title === null && this.checkOnHomePage()) {
          this.title = this.home_title;
          this.title_is_home_title = true;
        }
      })
    }
  }

  checkOnHomePage() {
    return this.router.url === '/';
  }

  setLocation(postcode) {
    this.search_location = postcode;
    this.dropdownShow = false;
  }

  async showDropdown() {
    this.dropdownShow = false;
    this.postcodes = [];

    if (this.search_location !== '') {
      this.postcodes = await this.geolocation.getPostcodesFromInput(this.search_location);
      this.postcodes_total = this.postcodes.length;
      this.dropdownShow = true;
    } else {
      this.dropdownShow = false;
    }
  }
  
  closeDropdown() {
    if (this.dropdownShow === true) {
      this.dropdownShow = false;
    }
  }
}
