import { AuthService } from 'src/app/services/auth/auth.service';
import { ApiService } from 'src/app/services/api/api.service';
import { NotyfService } from './../notyf/notyf.service';
import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { CartItem } from 'src/app/interfaces/cart-item';
import * as moment from 'moment';

const CLEAR_CART_TIMEOUT = 24 * 60 * 60 * 1000;

@Injectable({
  providedIn: 'root'
})
export class CartService {
  private _cart: CartItem[] = [];
  private _oldCart: CartItem[] = [];
  private _overwriteCart: CartItem[] = [];
  private _oldCartSet: boolean = false;
  cart$: Observable<any>;
  private cartSubject: BehaviorSubject<any>;

  private cart_timeout = null;

  private loggedIn: boolean = false;

  private externalCartId: number = null;

  constructor(
    private router: Router,
    private notyf: NotyfService,
    private api: ApiService,
  ) {
    this._cart = this.getCartFromStorage();
    this.cartSubject = new BehaviorSubject<any>(this._cart);
    this.cart$ = this.cartSubject.asObservable();
  }

  addBooking(
    service_id: number, 
    service_name: string, 
    employee_id: number, 
    employee_name: string, 
    date: string, 
    time: string, 
    price: number, 
    salon_id: number, 
    salon_name: string,
    notes: string
  ) {
    const booking = {
      service_id,
      service_name,
      employee_id,
      employee_name,
      date,
      time,
      price,
      added: new Date(),
      salon_id,
      salon_name,
      notes,
    };

    let cart = this._cart;

    if (cart.length === 0) {
      cart = this.getCartFromStorage();
    }

    cart.push(booking);
    localStorage.setItem('cart', JSON.stringify(cart));

    this.cartSubject.next(cart);

    this.resetTimeout();

    return cart;
  }

  addItemExternal(booking: CartItem) {
    this.api.post('/cart/add-item', booking).subscribe(this.handleExternalResponse);
  }

  private handleExternalResponse(response) {
    if (response['success'] === true) {
      if (typeof response['data'] !== 'undefined') {
        this._cart = response['data']['items'];
        this.cartSubject.next(this._cart);
        localStorage.setItem('cart', JSON.stringify(this._cart));
  
        if (response['data']['deleted_items']) {
          response['data']['deleted_items'].forEach((item: CartItem) => {
            const booking_date = moment(`${item.date} ${item.time}`, 'YYYY-MM-DD HH:mm');
  
            this.notyf.open({
              type: 'info',
              message: `Your appointment for a ${item.service_name} at ${item.salon_name} on ${booking_date.format('ddd Do MMM')} at ${booking_date.format('H:mm A')} has expired. It has been removed from your cart.`,
              dismissible: true,
              duration: 1000 * 10 // 10 seconds
            });
          });
        }
      }
    }
  }

  private resetTimeout() {
    if (this.cart_timeout !== null) {
      clearTimeout(this.cart_timeout);
    }

    if (this.cartSubject.value.length > 0) {
      this.cart_timeout = setTimeout(() => {
        this.clearCart();
        this.notyf.open({
          type: 'info',
          message: 'Your cart has been emptied due to inactivity.',
          dismissible: true,
          duration: 0
        })
      }, CLEAR_CART_TIMEOUT);
    }
  }

  private getCartFromStorage() {
    return JSON.parse(localStorage.getItem('cart')) || [];
  }

  getCart() {
    // if (this.checkIfOnCheckoutPage() === false && this._oldCartSet === true) {
    //   this._cart = this._oldCart;
    //   this.cartSubject.next(this._cart);
    // }

    // if (this.checkIfOnCheckoutPage() === true && this._oldCartSet === true) {
    //   this._cart = this._overwriteCart;
    //   this.cartSubject.next(this._cart);
    // }

    let cart = this.cartSubject.getValue();

    if (cart.length === 0) {
      cart = this.getCartFromStorage();
    }

    const now = moment();

    cart.forEach((item: CartItem, index: number) => {
      const booking_date = moment(`${item.date} ${item.time}`, 'YYYY-MM-DD HH:mm');

      if (booking_date.isSameOrBefore(now)) {
        this.removeItem(index); // will update the cart across the app, with the exception of the current cart array
        delete cart[index]; // will only affect the array we are currently looping over

        this.notyf.open({
          type: 'info',
          message: `Your appointment for a ${item.service_name} at ${item.salon_name} on ${booking_date.format('ddd Do MMM')} at ${booking_date.format('H:mm A')} has expired. It has been removed from your cart.`,
          dismissible: true,
          duration: 1000 * 10 // 10 seconds
        });
      }
    });

    if (cart.length > 0) {
      this.resetTimeout();
    }

    return cart;
  }

  async getCartExternal() {
    const response = await this.api.get('/cart').toPromise();
    
    if (response['success'] === true) {
      const cart = response['data']['items'];

      this._cart = cart;
      this.cartSubject.next(this._cart);
      localStorage.setItem('cart', JSON.stringify(this._cart));

      return cart;
    }

    return false;
  }

  saveCartExternal() {
    this.api.post('/cart/save-cart', {items: this.getCart()}).subscribe(this.handleExternalResponse)
  }

  overwriteCart(cart: CartItem[]) {
    if (this._cart.length > 0 && this._oldCartSet === false) {
      this._oldCart = this._cart;
      this._oldCartSet = true;
    }

    if (! (this._overwriteCart == cart)) {
      this._cart = cart;
      this._overwriteCart = cart;
  
      this.cartSubject.next(this._cart);
    }

    this.resetTimeout();
  }

  removeItem(index: number) {
    const cart = this.cartSubject.getValue();

    if (cart[index]) {
      cart.splice(index, 1);
    }

    localStorage.setItem('cart', JSON.stringify(cart));
    this.cartSubject.next(cart);

    this.resetTimeout();

    return cart;
  }

  removeItemExternal(index: number) {
    this.api.post('/cart/remove-item', {index}).subscribe(this.handleExternalResponse)
  }

  clearCart() {
    this._cart = [];

    localStorage.setItem('cart', JSON.stringify(this._cart));
    localStorage.removeItem('paymentIntentId');
    
    this.cartSubject.next(this._cart);
    
    this.resetTimeout();

    return this._cart;
  }

  clearCartExternal() {
    return this.api.post('/cart/clear-cart').toPromise();
  }

  private checkIfOnCheckoutPage() {
    return (this.router.url === '/checkout');
  }

  // checkCartForExpiredItems() {
  //   this._cart.forEach(item => {
  //   })
  // }
}
