import { AppStoreLogosComponent } from './../components/app-store-logos/app-store-logos.component';
import { MobilePageHeaderComponent } from './../components/mobile-page-header/mobile-page-header.component';
import { PageHeaderComponent } from './../components/page-header/page-header.component';
import { HeroNavComponent } from './../components/hero-nav/hero-nav.component';
import { ReplacePipe } from './../pipes/replace.pipe';
import { NavbarComponent } from './../components/navbar/navbar.component';
import { AvailableAppointmentsComponent } from './../components/available-appointments/available-appointments.component';
import { DateFormatPipe } from './../pipes/date-format.pipe';
import { TogglePasswordDirective } from './../directives/toggle-password.directive';
import { AppointmentComponent } from './../components/appointment/appointment.component';
import { SalonListComponent } from './../components/salon-list/salon-list.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { RouterModule } from '@angular/router';
// import { SlickCarouselModule } from 'ngx-slick-carousel';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { ButtonsModule } from 'ngx-bootstrap/buttons';
import { CarouselModule } from 'ngx-bootstrap/carousel';

import { SearchComponent } from '../components/search/search.component';
import { HeroSliderComponent } from '../components/hero-slider/hero-slider.component';
import { FavouritesSliderComponent } from '../components/favourites-slider/favourites-slider.component';
import { ServicesListComponent } from '../components/services-list/services-list.component';
import { ServicesListItemComponent } from '../components/services-list-item/services-list-item.component';
import { ClickPreventDefaultDirective } from '../directives/click-prevent-default.directive';
import { FooterComponent } from '../components/footer/footer.component';
import { EnvironmentServiceProvider } from '../services/environment/environment.service.provider';
import { ApiServiceProvider } from '../services/api/api.service.provider';
import { TokenInterceptor } from '../interceptors/token.interceptor';
import { StarRatingComponent } from '../components/star-rating/star-rating.component';
import { CategoryListComponent } from '../components/category-list/category-list.component';
import { MapComponent } from '../components/map/map.component';
import { CurrencyPipe } from '../pipes/currency.pipe';
import { SafeHtmlPipe } from '../pipes/safe-html.pipe';
import { MainContainerComponent } from '../components/main-container/main-container.component';

import { ClickOutsideDirective } from '../directives/click-outside.directive';

@NgModule({
  declarations: [
    SearchComponent,
    HeroSliderComponent,
    FavouritesSliderComponent,
    ServicesListComponent,
    ServicesListItemComponent,
    FooterComponent,
    StarRatingComponent,
    CategoryListComponent,
    MapComponent,
    SalonListComponent,
    AppointmentComponent,
    MainContainerComponent,
    AvailableAppointmentsComponent,
    NavbarComponent,
    ClickPreventDefaultDirective,
    TogglePasswordDirective,
    SafeHtmlPipe,
    ReplacePipe,
    CurrencyPipe,
    DateFormatPipe,
    ClickOutsideDirective,
    HeroNavComponent,
    PageHeaderComponent,
    MobilePageHeaderComponent,
    AppStoreLogosComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    ReactiveFormsModule,
    HttpClientModule,
    RouterModule,
    // SlickCarouselModule,
    LeafletModule,
    BsDatepickerModule,
    ButtonsModule,
    TabsModule,
    AccordionModule,
    CarouselModule,
  ],
  exports: [
    CommonModule,
    FormsModule,
    IonicModule,
    SearchComponent,
    HeroSliderComponent,
    FavouritesSliderComponent,
    ServicesListComponent,
    ServicesListItemComponent,
    FooterComponent,
    StarRatingComponent,
    CategoryListComponent,
    MapComponent,
    SalonListComponent,
    AppointmentComponent,
    MainContainerComponent,
    AvailableAppointmentsComponent,
    NavbarComponent,
    ClickPreventDefaultDirective,
    TogglePasswordDirective,
    ReactiveFormsModule,
    SafeHtmlPipe,
    CurrencyPipe,
    ReplacePipe,
    BsDatepickerModule,
    ButtonsModule,
    TabsModule,
    AccordionModule,
    CarouselModule,
    DateFormatPipe,
    ClickOutsideDirective,
    HeroNavComponent,
    PageHeaderComponent,
    MobilePageHeaderComponent,
    AppStoreLogosComponent,
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
    SafeHtmlPipe,
    CurrencyPipe,
    ReplacePipe,
  ]
})
export class SharedModule { }
