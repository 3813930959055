import { FcmService } from './../../services/fcm/fcm.service';
import { GalleryPage } from './../../pages/gallery/gallery.page';
import { element } from 'protractor';
import { AfterViewChecked, Component, ContentChildren, DoCheck, OnChanges, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { NgContentAst } from '@angular/compiler';
import { SSL_OP_SSLEAY_080_CLIENT_DH_BUG } from 'constants';

@Component({
  selector: 'app-main-container',
  templateUrl: './main-container.component.html',
  styleUrls: ['./main-container.component.scss'],
})
export class MainContainerComponent implements DoCheck {

  @ViewChild('mainContainer') main_container;

  private height: number;

  constructor(
    private fcm: FcmService,
  ) {}

  ngDoCheck() {
    this.doStuff();
    // this.fcm.setupFCM();
  }

  private doStuff() {
    const page = document.querySelector('ion-router-outlet .ion-page:not(.ion-page-hidden)');

    if (this.main_container) {
      if (page) {
        const footer = page.querySelector('app-footer');
        const navbar = document.querySelector('header');
    
        if (footer) {
          const content_wrapper = this.main_container.nativeElement.querySelector('#content-wrapper');
          const content_height = navbar.clientHeight + content_wrapper.clientHeight + footer.clientHeight;

          if (content_height < document.body.clientHeight) {
            footer.classList.add('position-absolute');
          } else {
            footer.classList.remove('position-absolute');
          }
        }
      }
    }
  }
}
