import { GeolocationService } from './services/geolocation/geolocation.service';
import { VoucherService } from './services/voucher/voucher.service';
import { SeoService } from './services/seo/seo.service';
import { SettingsService } from './services/settings/settings.service';
import { FcmService } from './services/fcm/fcm.service';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { NgModule } from '@angular/core';
import { BrowserModule, Title } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { SharedModule } from './shared/shared.module';
import { environment } from '../environments/environment';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NavbarComponent } from './components/navbar/navbar.component';
import { FavouritesService } from './services/favourites/favourites.service';
import { NgbDatepickerModule, NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { CartService } from './services/cart/cart.service';
import { ApiServiceProvider } from './services/api/api.service.provider';
import { EnvironmentServiceProvider } from './services/environment/environment.service.provider';

import { FCM } from 'cordova-plugin-fcm-with-dependecy-updated/ionic/ngx';

// import { AngularFireMessagingModule } from '@angular/fire/messaging';
// import { AngularFireModule } from '@angular/fire';
// import { FirebaseService } from './services/firebase/firebase.service';

import { Geolocation } from '@ionic-native/geolocation/ngx';
import { NativeGeocoder } from '@ionic-native/native-geocoder/ngx';
import { CategoriesService } from './services/categories/categories.service';

let imports = [
  BrowserModule, 
  IonicModule.forRoot(), 
  AppRoutingModule, 
  SharedModule,
  LeafletModule,
  BrowserAnimationsModule,
  NgbDatepickerModule,
  TabsModule.forRoot(),
  NgbDropdownModule,
];

const mobileOnlyImports = [];
const webOnlyImports = [
  // AngularFireModule.initializeApp(environment.firebase),
  // AngularFireMessagingModule,
];

if (window['__env'].platform === 'mobile') {
  mobileOnlyImports.forEach(val => {
    imports.push(val)
  });
}

if (window['__env'].platform === 'web') {
  webOnlyImports.forEach(val => {
    imports.push(val)
  });
}

@NgModule({
  declarations: [
    AppComponent,
  ],
  entryComponents: [],
  imports: imports,
  providers: [
    StatusBar,
    SplashScreen,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    EnvironmentServiceProvider,
    ApiServiceProvider,
    LeafletModule,
    CartService,
    FavouritesService,
    SettingsService,
    Title,
    SeoService,
    VoucherService,
    FCM,
    FcmService,
    // FirebaseService,
    Geolocation,
    NativeGeocoder,
    GeolocationService,
    CategoriesService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
