import { Directive, ElementRef } from '@angular/core';

@Directive({
  selector: '[toggle-password]'
})
export class TogglePasswordDirective {

  _shown = false;

  constructor(private el: ElementRef) { 
    const native = this.el.nativeElement;
    const parent = native.parentNode;
    const inputGroup = document.createElement('div');
    const inputGroupAppend = document.createElement('div');
    const inputGroupText = document.createElement('span');
    const togglePasswordIcon = document.createElement('i');

    inputGroup.classList.add('input-group');
    inputGroupAppend.classList.add('input-group-append');
    inputGroupText.classList.add('input-group-text');
    togglePasswordIcon.classList.add('fas', 'fa-eye');

    inputGroupAppend.addEventListener('click', () => {
      this.toggle(inputGroup);
    })

    inputGroupText.append(togglePasswordIcon);
    inputGroupAppend.append(inputGroupText);
    inputGroup.append(native, inputGroupAppend);



    // <div class="input-group-append">
    //               <span class="input-group-text">
    //                 <i class="fas fa-eye"></i>
    //               </span>
    //             </div>

    native.replace()
  }

  toggle(inputGroup: HTMLElement) {
    const icon = inputGroup.querySelector('i.fas');
    const input = inputGroup.querySelector('input');

    this._shown = ! this._shown;

    if (this._shown) {
      input.setAttribute('type', 'text');
      icon.classList.remove('fa-eye');
      icon.classList.add('fa-eye-slash');
    } else {
      input.setAttribute('type', 'password');
      icon.classList.remove('fa-eye-slash');
      icon.classList.add('fa-eye');
    }
  }

}
