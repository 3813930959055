import { VoucherService } from './../voucher/voucher.service';
import { CartService } from './../cart/cart.service';
import { AlertController, NavController } from '@ionic/angular';
import { User } from './../../interfaces/user';
import { TabsyncService } from './../tabsync/tabsync.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiService } from '../api/api.service';
import { EnvironmentService } from '../environment/environment.service';
import * as _moment from 'moment';
import { shareReplay, tap } from 'rxjs/operators';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { NotyfService } from '../notyf/notyf.service';

const moment = _moment;

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  loggedInMessage = 'logged in';
  loggedOutMessage = 'logged out';

  baseUrl: string;
  options: any;

  loggedIn$: Observable<boolean>;

  private loggedInSubject: BehaviorSubject<boolean>;

  constructor(
    private api: ApiService,
    private environment: EnvironmentService,
    private tabsync: TabsyncService,
    private notyf: NotyfService,
    private alertController: AlertController,
    private navController: NavController,
    private cartService: CartService,
    private voucherService: VoucherService,
  ) {
    this.loggedInSubject = new BehaviorSubject<boolean>(this.getAccessToken() !== null);
    this.loggedIn$ = this.loggedInSubject.asObservable();
  }

  getAccessToken() {
    return localStorage.getItem('access_token');
  }

  getRefreshToken() {
    return localStorage.getItem('refresh_token');
  }

  getExpiration() {
    const expiration = localStorage.getItem('expires_in');
    const expires_at = JSON.parse(expiration);

    return moment(expires_at);
  }

  getTokenType() {
    return localStorage.getItem('token_type');
  }

  async isLoggedIn(displayAlert: boolean = true, checkBan: boolean = true) {

    if (this.getAccessToken() !== null) {
      let response;

      try {
        response = await this.api.post('/check-logged-in').toPromise();

        // this.loggedInSubject.next(true);
      }
      catch(err) {
        this.logout();

        if (displayAlert === true) {
          const alert = await this.alertController.create({
            header: 'You have been logged out',
            message: 'Click OK to go to the home page',
            buttons: [
              {
                text: 'Login',
                handler: () => {
                  this.navController.navigateRoot('/login');
                }
              },
              {
                text: 'OK',
                handler: () => {
                  this.navController.navigateRoot('/');
                }
              },
            ]
          });

          await alert.present();
        }

        return false;
      }

      var banResponse = await this.checkUserBan();

      if(banResponse.banned && checkBan){

        this.logout();

        this.notyf.error('Your account has been suspened');

        return false

      }

      return this.getAccessToken() && moment().isBefore(this.getExpiration());
    }

    return false;
  }

  async checkUserBan() {

      let response;

      try {
        response = await this.api.post('/check-user-ban').toPromise();
      }
      catch(err) {

        this.logout();

        return false;

      }

      if(response.banned){

        this.cartService.clearCart();
        this.voucherService.removeVoucher();

      }

      return response;

  }

  login(username: string, password: string) {
    return this.api.post('/oauth/token', {
      grant_type: 'password',
      client_id: this.environment.api.client_id,
      client_secret: this.environment.api.client_secret,
      username: username,
      password: password,
      scope: ''
    }).pipe(
      tap(async authResponse => {
        this.setSession(authResponse);
      }),
      shareReplay()
    );
  }

  logout() {
    this.cartService.clearCartExternal().then(async (response) => {
      await this.api.post('/revoke-token').toPromise();
      
      this.handleLogout();
    }).catch(() => {
      this.handleLogout();
    })

    this.tabsync.postMessage('logged out');

    this.loggedInSubject.next(false);
  }

  setSession(oauthResponse) {
    const expires_at = moment().add(oauthResponse.expires_in, 'second');

    localStorage.setItem('access_token', oauthResponse.access_token);
    localStorage.setItem('refresh_token', oauthResponse.refresh_token);
    localStorage.setItem('token_type', oauthResponse.token_type);
    localStorage.setItem('expires_in', JSON.stringify(expires_at));

    this.tabsync.postMessage('logged in');

    this.loggedInSubject.next(true);
  }

  getUser(): User {
    return JSON.parse(localStorage.getItem('user'));
  }

  private async handleLogout() {
    localStorage.removeItem('access_token');
    localStorage.removeItem('refresh_token');
    localStorage.removeItem('token_type');
    localStorage.removeItem('expires_in');
    this.cartService.clearCart();
    this.voucherService.removeVoucher();
  }
}
