import { Category } from './../../interfaces/category';
import { CategoriesService } from 'src/app/services/categories/categories.service';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-hero-nav',
  templateUrl: './hero-nav.component.html',
  styleUrls: ['./hero-nav.component.scss'],
})
export class HeroNavComponent implements OnInit {

  // @Input() active_category: Category = null;
  @Input() link: string = null;
  @Input() show_active = true;

  active_category: Category = null;

  categories: Category[] = [];

  active_slug: string = '';

  constructor(
    private categoriesService: CategoriesService,
  ) { }

  ngOnInit() {
    this.categoriesService.categories$.subscribe(categories => {
      this.categories = categories;
    })

    if (this.active_category === null) {
      this.active_category = this.categoriesService.getActiveCategory();
    }

    this.active_slug = this.active_category?.slug || '';
  }

  setActiveCategory(category: Category) {
    this.categoriesService.setActiveCategory(category);
  }
}
