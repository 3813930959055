import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AlertController, NavController } from '@ionic/angular';
import { AuthService } from '../services/auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  loggedIn = false;

  constructor(
    private alertController: AlertController,
    private navController: NavController,
    private auth: AuthService
  ) { }

  async canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (this.auth.getAccessToken()) { 
      const loggedIn = await this.auth.isLoggedIn();

      if (loggedIn === true) {
        this.loggedIn = true;
        return true; 
      }
    }

    this.loggedIn = false;

    const alert = await this.alertController.create({
      header: 'You need to be logged in to view this page',
      message: 'Press OK to go to login, or Cancel to remain on the current page.',
      buttons: [
        'Cancel',
        {
          text: 'OK',
          handler: () => {
            this.navController.navigateRoot('/login', {
              queryParams: {
                return: state.url
              }
            });
          }
        }
      ],
      mode: 'md'
    });

    await alert.present();

    return false;
  }
}
